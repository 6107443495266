import { createSlice } from "@reduxjs/toolkit";
import { fetchBlogPost } from "./blogThunks";

const initialState = {
  loading: false,
  blogs: [],
  currentPost: null,
  status: "idle",
  error: null,
};

export const blogReducer = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    getBlogs: (state, { payload }) => {
      state.blogs = payload;
    },
    setCurrentPost: (state, action) => {
      state.currentPost = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogPost.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchBlogPost.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.currentPost = action.payload;
        state.loading = false;
      })
      .addCase(fetchBlogPost.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export const { getBlogs, setCurrentPost } = blogReducer.actions;

export default blogReducer.reducer;
