import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getBlogs } from "../redux/features/blogs/blogReducer";
import sanityClient from "../sanityClient";

export const useBlogs = () => {
  // const [loading, setLoading] = useState()
  const dispatch = useDispatch();
  const [blogs, setBlogs] = useState(null);

  useEffect(() => {
    async function getPosts() {
      try {
        const posts =
          await sanityClient.fetch(`*[_type == "post"] | order(publishedAt desc) {
          title,
          slug,
          body,
          categories[]->{ title },
          author->{ bio, name, image  { asset->{ _id, url } } },
          mainImage{
            asset->{
              _id,
              url
            },
          },
          publishedAt,
          hexCode,
        }`);

        setBlogs(posts);
        dispatch(getBlogs(posts));
      } catch (error) {
        // console.log(error, "error fetching blog posts");
      }
    }

    getPosts();
  }, [dispatch]);
};
