import { Link } from "react-router-dom";
import Subtitle from "../../components/utils/typography/subtitle";
import Title from "../../components/utils/typography/title";
import "./404.scss";

const PageNotFound = () => {
  return (
    <div className="not_found_page  flex justify-center align-middle flex-col">
      <img
        src={require("../../components/assets/images/404.png")}
        alt="..."
        className="pb-0 mb-0 w-full object-contain bg-no-repeat"
      />
      <div className="content text-center mx-auto z-50 mb-10">
        <p className="text-center ">
          <Title title="You seem to be lost 😰" />
          <Link className="text-green-500" to="/">
            <Subtitle
              className="text-green-500 lowercase"
              subtitle="Go back home"
            />
          </Link>
        </p>
      </div>
    </div>
  );
};

export default PageNotFound;
