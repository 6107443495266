import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import sanityClient from "../../sanityClient";
import { serializers } from "../../components/utils/serializer";
import config from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogPost } from "../../redux/features/blogs/blogThunks";
import { setCurrentPost } from "../../redux/features/blogs/blogReducer";

const BlogDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const builder = imageUrlBuilder(sanityClient);
  const { currentPost, status, error } = useSelector((state) => state.blogs);

  const slug = location.pathname.split("/").pop();

  useEffect(() => {
    if (!currentPost || currentPost.slug.current !== slug) {
      dispatch(fetchBlogPost(slug));
    }
  }, [dispatch, currentPost, slug]);

  function urlFor(source) {
    if (!source) return "";
    return builder.image(source).url();
  }

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  if (!currentPost) {
    return null;
  }

  const { title, mainImage, author, body } = currentPost;

  const { SANITY_PROJECT_ID } = config;

  return (
    <div className="h-full px-5 py-5">
      <div className="w-full h-screen overflow-hidden ">
        <img
          className="object-cover object-center w-full h-full p-10 bg-center bg-contain"
          src={urlFor(mainImage?.asset?.url)}
          alt="Main background"
          loading="eager"
        />
      </div>

      <div className=" lg:px-[16em] py-10">
        <h2 className="text-5xl font-bold text-center capitalize text-slate-800 dark:text-slate-100 prose-h1:p-2 ">
          {title}
        </h2>
        <div className="flex justify-start pb-8 mt-10">
          <img
            className="h-16 rounded-full w-14"
            src={urlFor(author?.image?.asset)}
            alt="..."
          />

          <p className="pt-1 pl-3 text-lg capitalize text-slate-800 dark:text-slate-100">
            {author?.name}
            <br />
            {author?.bio[0]?.children[0].text}
          </p>
        </div>
        <article
          className="w-full max-w-none text-slate-800 dark:text-slate-100
          first-letter:text-[80px] text-lg
          first-letter:capitalize leading-10 tracking-wider first-letter:font-bold text"
        >
          <BlockContent
            projectId={SANITY_PROJECT_ID}
            dataset="production"
            blocks={body}
            imageOptions={{ w: 2000, h: 500, fit: "max" }}
            serializers={serializers}
          />
        </article>
      </div>
    </div>
  );
};

export default BlogDetails;
