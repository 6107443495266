import React from "react";
import Lead from "../utils/typography/lead";
import "./pageheader.scss";

export default function index({
  title,
  subtitle,
  className = "",
  children,
  id,
}) {
  return (
    <>
      <div className={`giftcard_header ${className}`} id={id}>
        <div className="header_cont">
          <div className="text_cont box">
            <h1 className="title">{title}</h1>
            {children}
            <Lead className="subtitle !text-slate-50" lead={subtitle} />
            {/* <Button
              className="ph_btn mt-3"
              ctaText="start now"
              ctaLink="/dashboard"
            /> */}
          </div>
        </div>
      </div>
    </>
  );
}
