import { useSelector } from "react-redux";

export default function Title({ title, className = "", handleClick }) {
  const theme = useSelector((state) => state.theme.theme);

  return (
    <h1
      className={`title
         mt-2
        text-3xl
        leading-10
        font-bold
        tracking-tight
        capitalize
        sm:text-3xl
        ${className} 
        ${theme === "dark" ? " text-slate-50" : " text-gray-800"}
        `}
      onClick={handleClick}
    >
      {title}
    </h1>
  );
}
