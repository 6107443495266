import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Navigation from '../Navbar';
import loadable from '@loadable/component';
// import ConfettiExplosion from "react-confetti-explosion";

import './layout.scss';

const Footer = loadable(() => import('../Footer'));

const Layout = ({ children }) => {
  // const [isExploding, setIsExploding] = useState(false);

  // const handleClick = () => {
  //   setIsExploding(true);

  //   setTimeout(() => {
  //     setIsExploding(false);
  //   }, 5000);
  // };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     handleClick();
  //   }, 60000);

  //   return () => clearInterval(intervalId);
  // }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kaapo</title>
        <link rel="canonical" href="https://kaapo.ng" />
      </Helmet>
      <div className="relative w-full ">
        <div className="fixed w-full z-[5000]">
          <Navigation white />
        </div>

        <div className="relative">{children}</div>

        {/* christmas wishes */}
        {/* <div className="fixed bottom-0 right-0 z-50 bg-left-bottom">
          <div
            className="relative px-8 pt-10 pb-10 cursor-pointer"
            onClick={handleClick}
          >
            {isExploding && (
              <>
                <ConfettiExplosion
                  force={0.4}
                  duration={5000}
                  particleCount={30}
                  height={500}
                  width={300}
                />
              </>
            )}
            <img
              className="w-24 h-24 pb-0 mx-auto transition-all duration-700 transform cursor-pointer"
              src="https://www.citypng.com/public/uploads/small/11665338698twjwyfyyy6abejqkcfmpqucc4n1siubygcyclmdhpq71x5gnxaard1uz9kfqxgkaux3wiq7nl7v34sk7gl3nlcvcwzyiiunjxokq.png"
              alt="..."
            />
            <p className="absolute text-lg font-semibold text-center text-green-600 capitalize -mt-14 christmas-text">
              merry christmas <br /> & a happy new year
            </p>
          </div>
        </div> */}

        <Footer />
      </div>
    </>
  );
};

export default Layout;
