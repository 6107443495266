import React, { useState } from "react";
import Blog from "../../components/Blog";
import { CiSearch } from "react-icons/ci";
import Title from "../utils/typography/title";
import Typewriter from "typewriter-effect";

const LatestPost = ({ blogs }) => {
  const [selected, setSelected] = useState("all categories");
  const [search, setSearch] = useState("");

  const reduceTitle = (titleArray) =>
    titleArray
      ? titleArray.reduce((acc, titleObj) => acc.concat(titleObj.title), [])
      : [];

  const allFilterBlogs =
    selected === "all categories"
      ? filterBySearched(blogs, search)
      : filterBySearched(
          blogs.filter((blog) =>
            blog.categories
              ? reduceTitle(blog.categories).includes(selected)
              : false
          ),
          search
        );

  const handleFilterButtonClick = (category) => {
    setSelected(category);
  };

  function filterBySearched(blogs, searchTerm) {
    if (!searchTerm) return blogs;
    return blogs.filter((blog) => {
      return (
        blog.title.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
        reduceTitle(blog.categories)
          .join(" ")
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase())
      );
    });
  }

  const filters = ["all categories"].concat([
    ...new Set(
      blogs.reduce((acc, blog) => {
        return blog.categories
          ? acc.concat(blog.categories.map((category) => category.title))
          : acc;
      }, [])
    ),
  ]);

  return (
    <div className="ml-7">
      <div className="mt-10 ml-12 max-w-2x">
        <h3 className="text-4xl font-bold text-blue-500 dark:text-white">
          Featured Blogs
        </h3>
      </div>
      <div className="flex flex-wrap gap-5 mx-auto mt-3 mb-5 ml-12">
        {filters.map((category, idx) => (
          <button
            key={category}
            onClick={() => handleFilterButtonClick(category)}
            className={`p-2 text-[14px] capitalize text-white hover:bg-blue-700 ${
              selected === category ? "bg-blue-400" : "bg-blue-200"
            } rounded-3xl`}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="relative flex pl-10 mb-10 mr-10 sm:justify-start md:justify-end">
        <input
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search articles..."
          className="flex pl-10 rounded-full w-[400px] border-none focus:border-none dark:placeholder:text-slate-300 active:border-green-600 bg-slate-300 dark:bg-blue-400 dark:text-slate-100 transition-colors duration-500"
        />
        <button className="absolute top-0 mt-2 ml-2 mr-5 text-2xl">
          <CiSearch className="text-green-500 dark:text-slate-100" />
        </button>
      </div>
      {allFilterBlogs.length > 1 ? (
        <Blog location={"blog"} blogPost={allFilterBlogs} />
      ) : (
        <h3 className="pb-10 text-center">
          <Typewriter
            options={{
              strings: ["No matching articles..."],
              autoStart: true,
              loop: true,
              cursor: "😔",
              wrapperClassName:
                " text-2xl !text-center font-bold mt-2 text-3xl leading-10 font-bold tracking-tight capitalize sm:text-3xl ",
              cursorClassName: "text-2xl",
            }}
            onInit={(typewriter) => {
              typewriter.pauseFor(1200).deleteAll().start();
            }}
          />
        </h3>
      )}
    </div>
  );
};

export default LatestPost;
