export const serializers = {
  marks: {
    link: (props) => (
      <a className="text-[#4e6cf6] no-underline" href={props.mark.href}>
        {props.children}
      </a>
    ),
  },
  types: {
    block: (props) => {
      switch (props.node.style) {
        case 'h1':
          return (
            <h1 className="mb-8 text-5xl font-bold leading-tight text-gray-900 dark:text-gray-100">
              {props.children}
            </h1>
          );
        case 'h2':
          return (
            <h2 className="mb-6 text-4xl font-bold leading-tight text-gray-800 dark:text-gray-100">
              {props.children}
            </h2>
          );
        case 'h3':
          return (
            <h3 className="mb-4 text-3xl font-semibold leading-snug text-gray-700 dark:text-gray-100">
              {props.children}
            </h3>
          );
        case 'h4':
          return (
            <h4 className="mb-3 text-2xl font-semibold leading-snug text-gray-600 dark:text-gray-100">
              {props.children}
            </h4>
          );
        case 'h5':
          return (
            <h5 className="mb-2 text-xl font-semibold leading-snug text-gray-600 dark:text-gray-100">
              {props.children}
            </h5>
          );
        case 'h6':
          return (
            <h6 className="mb-1 text-lg font-semibold leading-snug text-gray-600 dark:text-gray-100">
              {props.children}
            </h6>
          );
        case 'blockquote':
          return (
            <blockquote className="p-6 my-8 italic bg-gray-100 border-l-4 border-gray-400 rounded-lg">
              {props.children}
            </blockquote>
          );
        case 'image':
          return (
            <div className="p-10 bg-red-500">
              <img
                src={props.node.url}
                alt={props.node.alt}
                className="h-auto max-w-full rounded-sm "
              />
            </div>
          );
        default:
          return (
            <p className="mb-6 leading-relaxed text-gray-700 dark:text-gray-100">
              {props.children}
            </p>
          );
      }
    },
    code: (props) => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
    list: (props) => {
      const { type, children } = props;
      if (type === 'bullet') {
        return <ul className="prose prose-2xl list-decimal">{children}</ul>;
      }
      if (type === 'number') {
        return <ol>{children}</ol>;
      }
      return null;
    },
    listItem: (props) => <li>{props.children}</li>,
  },
};
