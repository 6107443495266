import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "../../sanityClient";
import Spinner from "../../components/Spinner";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const LatestArticle = ({ blog }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    blog !== undefined && setLoading(false);
  }, [blog]);

  return (
    <div className="flex items-center justify-center px-10 pt-40">
      {loading ? (
        <Spinner loading={loading} />
      ) : (
        // <h3>Loading....</h3>
        <div className="grid md:grid-cols-2 gap-5 place-items-center max-w-[1250px] mx-auto bg-slate-100 dark:dark:bg-[#0a1f4e] transition-colors duration-500 p-10 rounded-md shadow-sm shadow-indigo-500/40 dark:shadow-green-800/80">
          <div className="">
            <div className="inline-block p-2 mb-5 text-xs bg-blue-300 text-slate-100 rounded-xl ">
              <h5>Latest Article</h5>
            </div>
            <h1 className="mb-5 text-4xl text-slate-600 dark:text-slate-100 font-bold xl:text-[40px]">
              {blog?.title}
            </h1>
            {/* <p className="text-lg">{blog?.description}</p> */}
            <p className="mb-3 text-xl font-normal text-slate-600 dark:text-slate-100">
              {blog?.body
                .map((child) => child.children?.[0]?.text)
                .filter(Boolean)
                .join(" ")
                .substring(0, 100)}
              &nbsp;...
            </p>
            <h4
              onClick={() => {
                const data = { article: blog };
                navigate(`/details/${blog.slug.current}`, {
                  state: data,
                });
              }}
              className="inline-block mt-8 text-xl font-bold cursor-pointer text-slate-600 dark:text-slate-100 "
            >
              Read More...
            </h4>
          </div>
          <div>
            <img
              src={blog?.mainImage?.asset?.url}
              alt=""
              className="rounded-2xl h-[400px]"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LatestArticle;
