import React, { useState } from 'react';
import PageHeader from '../../components/PageHeader';
import LatestPost from '../../components/LatestBlogPost';
import LatestArticle from '../../components/LatestArticle';
import { useSelector } from 'react-redux';

const BlogPage = () => {
  const blogs = useSelector((state) => state.blogs.blogs);

  // console.log(blogs[0]);

  return (
    <div>
      {/* <PageHeader
        className="contactpage_hero"
        title="Blog"
        subtitle="Explore the latest updates, stories, and articles from the home of digital remittance, bill payments, and gift card brokerage services."
        img={require('../../components/assets/images/card.png')}
      /> */}
      <LatestArticle blog={blogs[0]} />
      <LatestPost blogs={blogs} />
    </div>
  );
};

export default BlogPage;
