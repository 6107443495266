import { configureStore } from "@reduxjs/toolkit";
import blogReducer from "./features/blogs/blogReducer";
import themeReducer from "./features/theme/themeReducer";

export const store = configureStore({
  reducer: {
    blogs: blogReducer,
    theme: themeReducer,
  },
});
