import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function Background({ children }) {
  const theme = useSelector((state) => state.theme.theme);

  // console.log("Background", theme);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  return (
    <div className="transition-all duration-500 bg-slate-100 dark:bg-[#040D21] text-gray-800 dark:text-slate-50">
      {children}
    </div>
  );
}
