import { useSelector } from "react-redux";

export default function Lead({ lead, className = "" }) {
  const theme = useSelector((state) => state.theme.theme);

  return (
    <p
      className={`font-normal
        mt-3
        mb-0
        text-base
        pb-0
        sm:mt-5
        sm:text-5xl
        sm:mx-auto
        md:mt-5
        md:text-lg
        lg:mx-auto
        ${className}
        ${theme === "dark" ? " text-slate-50" : " text-gray-800"}

        `}
    >
      {lead}
    </p>
  );
}
