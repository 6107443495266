import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaSignInAlt, FaArrowDown } from "react-icons/fa";
import Toggle from "../utils/themeToggle";
import "./navbar.scss";

const getNavbarClass = (props) => {
  if (props.transparent) return "text-gray-500";
  if (props.white) return "bg-slate-100 text-blue-800";
  if (props.solid) return "bg-blue-500 text-white";
  return "text-gray-500";
};

const getLinkClass = (props) => {
  if (props.transparent) return "text-blue-800 hover:text-gray-300";
  if (props.white) return "text-blue-800 hover:text-gray-600";
  return "text-white hover:text-gray-100";
};

export default function Navigation(props) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [productsDropdown, setProductsDropdown] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const navbarClass = getNavbarClass(props);
  const linkClass = getLinkClass(props);

  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY > lastScrollY) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }

        setLastScrollY(window.scrollY);
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <nav
      className={`
      ${navbarClass} 
      fixed top-0 left-0 w-full z-50
      transition-transform duration-300 ease-in-out
      ${isVisible ? "translate-y-0" : "-translate-y-full"}
      flex flex-wrap items-center justify-center text-center px-2 py-2 
      dark:bg-[#040D21] dark:text-slate-100 shadow-md
    `}
    >
      <div className="container flex flex-wrap items-center justify-between px-4 mx-auto">
        {/* Logo */}
        <Link to="/" className="py-1 mr-4 text-xl font-bold">
          <img
            className="h-[30px]  dark:hidden"
            src={require("./logo/Kaapo6.png")}
            alt="Kaapo Global Services"
          />
          <img
            className="h-[30px]  hidden dark:block"
            src={require("./logo/Kaapo5.png")}
            alt="Kaapo Global Services"
          />
          <small className="text-xs text-blue-500 dark:text-green-300">
            kaapo global services
          </small>
        </Link>

        {/* Mobile menu button */}
        <button
          className="px-3 py-1 text-xl lg:hidden"
          aria-label="Toggle navigation menu"
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          <FaBars className={`${linkClass} dark:text-slate-100`} />
        </button>

        {/* Navigation menu */}
        <div
          className={`lg:flex flex-grow items-center ${
            navbarOpen ? "block" : "hidden"
          }`}
        >
          <ul className="flex flex-col list-none lg:flex-row lg:ml-auto">
            <NavItem
              to="/about"
              label="About us"
              linkClass={linkClass}
              onClick={() => setNavbarOpen(false)}
            />

            {/* Products dropdown */}
            <li className="relative">
              <button
                className={`${linkClass} text-slate-800 dark:text-slate-100 hover:text-blue-500 dark:hover:text-green-500 px-3 py-2 flex items-center text-sm uppercase font-bold`}
                onClick={() => setProductsDropdown(!productsDropdown)}
                aria-expanded={productsDropdown}
              >
                Products <FaArrowDown className="ml-1" />
              </button>
              {productsDropdown && (
                <ul className="absolute left-0 mt-2 w-48 bg-white dark:bg-[#040D21] shadow-lg rounded">
                  <DropdownItem
                    to="/giftcard"
                    label="Giftcards"
                    linkClass={linkClass}
                    onClick={() => {
                      setProductsDropdown(false);
                      setNavbarOpen(false);
                    }}
                  />
                  <DropdownItem
                    to="/utilities"
                    label="Utilities"
                    linkClass={linkClass}
                    onClick={() => {
                      setProductsDropdown(false);
                      setNavbarOpen(false);
                    }}
                  />
                  <DropdownItem
                    to="/wallet"
                    label="Wallet"
                    linkClass={linkClass}
                    onClick={() => {
                      setProductsDropdown(false);
                      setNavbarOpen(false);
                    }}
                  />
                </ul>
              )}
            </li>

            <NavItem
              to="/contact-us"
              label="Contact us"
              linkClass={linkClass}
              onClick={() => setNavbarOpen(false)}
            />
            <NavItem
              to="/blog"
              label="Blog"
              linkClass={linkClass}
              onClick={() => setNavbarOpen(false)}
            />
            <NavItem
              to="https://staff.kaapo.ng/careers/14030b9ff449fed7bf84473f1828da86"
              label="Careers"
              linkClass={linkClass}
              onClick={() => setNavbarOpen(false)}
              external
            />

            {/* Sign in button */}
            <li className="flex items-center mt-4 lg:mt-0">
              <a href="https://dashboard.kaapo.ng" className="mr-3">
                <button
                  className="px-4 py-2 text-sm font-bold text-white uppercase transition-colors duration-300 bg-blue-800 rounded hover:bg-blue-700"
                  aria-label="Sign in"
                >
                  <FaSignInAlt className="inline-block mr-1" /> Sign in
                </button>
              </a>
              <Toggle />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

const NavItem = ({ to, label, linkClass, onClick, external }) => (
  <li className="lg:mx-2">
    <Link
      to={to}
      className={`${linkClass} text-slate-800 dark:text-slate-100 px-3 py-2 flex items-center text-sm uppercase font-bold hover:text-blue-500 dark:hover:text-green-500 transition-colors duration-300`}
      onClick={onClick}
      {...(external ? { target: "_blank", rel: "noopener noreferrer" } : {})}
    >
      {label}
    </Link>
  </li>
);

const DropdownItem = ({ to, label, linkClass, onClick }) => (
  <li>
    <Link
      to={to}
      className={`${linkClass} text-slate-800 dark:text-slate-100 block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-blue-500 dark:hover:text-green-500 transition-colors duration-300`}
      onClick={onClick}
    >
      {label}
    </Link>
  </li>
);
