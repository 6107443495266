// blogThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import sanityClient from "../../../sanityClient";

export const fetchBlogPost = createAsyncThunk(
  "blogs/fetchBlogPost",
  async (slug, { rejectWithValue }) => {
    try {
      const query = `*[_type == "post" && slug.current == $slug][0]{
        ...,
        author->{ bio, name, image  { asset->{ _id, url } } },
          mainImage{
            asset->{
              _id,
              url
            },
          },
      }`;
      const params = { slug };
      const post = await sanityClient.fetch(query, params);
      // console.log("post:", post);
      if (!post) throw new Error("Post not found");
      return post;
    } catch (error) {
      console.error("Error fetching post:", error);
      return rejectWithValue(error.message);
    }
  }
);
