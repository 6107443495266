import React from 'react';
import { Link } from 'react-router-dom';

const index = () => {
  return (
    <div className=" flex justify-center justify-items-center m-auto max-w-lg my-10 px-6">
      <Link
        to="/blog"
        className="block border border-blue-500 text-blue-500 text-center py-4 px-20 rounded-full hover:bg-blue-400 hover:text-slate-100 transition-all ease-in-out duration-500 dark:border-slate-200 dark:text-slate-100"
      >
        View More Blogs
      </Link>
    </div>
  );
};

export default index;
