import { useSelector } from "react-redux";

export default function Subtitle({ subtitle, className = "", white, blue }) {
  const theme = useSelector((state) => state.theme.theme);

  return (
    <h2
      className={
        (white ? "text-white" : blue ? "text-blue-800" : "") +
        `subtitle text-blue-800 dark:text-slate-100 dark:drop-shadow-md
        text-2xl
        capitalize
        py-2
        font-bold
        font-sans ${className}
        ${theme === "dark" ? " !text-slate-50" : " text-gray-800"}
        `
      }
    >
      {subtitle}
    </h2>
  );
}
