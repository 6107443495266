import React from "react";
import { FaSun, FaMoon } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../../../redux/features/theme/themeReducer";

export default function Toggle({ className = "" }) {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);

  const handleThemeChange = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    // console.log(newTheme, "new theme");
    dispatch(setTheme(newTheme));
  };

  return (
    <div
      className={`transition duration-700 ease-in-out rounded-full mx-5 my-auto ${className}`}
    >
      {theme === "dark" ? (
        <FaSun
          onClick={handleThemeChange}
          className="text-2xl cursor-pointer text-amber-600 dark:text-amber-600"
        />
      ) : (
        <FaMoon
          onClick={handleThemeChange}
          className="text-2xl text-gray-600 cursor-pointer dark:text-gray-600"
        />
      )}
    </div>
  );
}
