import { createClient } from "@sanity/client";
import config from "./config";
const { SANITY_PROJECT_ID } = config;

const sanityClient = createClient({
  projectId: SANITY_PROJECT_ID,
  dataset: "production",
  useCdn: true,
  apiVersion: "2024-01-15",
});
export default sanityClient;
