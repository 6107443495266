import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import ScrollToTop from './components/scrollToTop';
import PageNotFound from './pages/404';
import loadable from '@loadable/component';
import './App.scss';
import { useBlogs } from './api/blogs';
import BlogDetails from './pages/blogDetails';
import BlogPage from './pages/blogPage';

const Home = loadable(() => import('./pages/home'));
// const LoginPage = loadable(() => import("./pages/login"));
// const SignupPage = loadable(() => import("./pages/signupPage"));
const GiftCardPage = loadable(() => import('./pages/giftcardPage'));
const Utility = loadable(() => import('./pages/utilitiesAndTopUp'));
const Wallet = loadable(() => import('./pages/wallet'));
const Rates = loadable(() => import('./pages/rates'));
const About = loadable(() => import('./pages/about'));
const Contact = loadable(() => import('./pages/contact'));
const Privacy = loadable(() => import('./pages/legal/privacy'));

function App() {
  useBlogs();
  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          <Layout>
            <Routes>
              <Route path="/" exact element={<Home />} />
              {/* <Route path="/login" element={<LoginPage />} /> */}
              {/* <Route path="/signup" element={<SignupPage />} /> */}
              <Route path="/giftcard" element={<GiftCardPage />} />
              <Route path="/utilities" element={<Utility />} />
              <Route path="/wallet" element={<Wallet />} />
              <Route path="/contact-us" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/rates" element={<Rates />} />
              <Route path="/details/:slug" element={<BlogDetails />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="*" element={<PageNotFound />} />

              {/* private routes
            <Route path="/dashboard" element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
            </Route> */}
            </Routes>
          </Layout>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
