import React from 'react';
import { Bars } from 'react-loader-spinner';

const override = {
  display: 'block',
  margin: '100px auto',
};

const Spinner = (loading) => {
  return (
    <Bars
      color={['#336595']}
      loading={loading}
      cssOverride={override}
      size={150}
    />
  );
};

export default Spinner;
