import React, { useState } from "react";
import * as dayjs from "dayjs";

import imageUrlBuilder from "@sanity/image-url";
import { Link, useNavigate } from "react-router-dom";
import sanityClient from "../../sanityClient";
import Spinner from "../../components/Spinner";
import ViewAllBlogs from "../../components/ViewAllBlogs";
import Title from "../utils/typography/title";
import Lead from "../utils/typography/lead";
import { setCurrentPost } from "../../redux/features/blogs/blogReducer";
import { useDispatch } from "react-redux";

const advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const Blog = ({ blogPost, location }) => {
  const [limit, setLimit] = useState(3);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showMoreItems = () => {
    setLimit((prevValue) => prevValue + 3);
  };

  const desc = (d) => {
    return d.body
      .map((child) => child.children?.[0]?.text)
      .filter(Boolean)
      .join(" ")
      .substring(0, 100);
  };

  const handleReadMore = (article) => {
    dispatch(setCurrentPost(article));
    navigate(`/details/${article.slug.current}`);
  };

  const dayjs = require("dayjs");
  return (
    <div className="w-full px-10 mx-auto mb-0 h-fit ">
      {!blogPost ? (
        <h3>Loading....</h3>
      ) : (
        // <Loader />
        <div className="grid grid-cols-1 min-card:grid-cols-[repeat(auto-fill,_minmax(370px,1fr))] gap-5 ">
          {blogPost?.slice(0, limit).map((d, i) => {
            return (
              <div
                className="z-50 mb-10 mx-auto h-[560px] min-card:w-[400px]  w-full max-card:w-full
               rounded-md text-base text-slate-500 dark:bg-[#0a1f4e] lg:text-lg shadow-sm shadow-indigo-500/40 dark:shadow-green-800/80"
                key={i}
              >
                <div className="">
                  <img
                    className=""
                    src={urlFor(d.mainImage.asset.url)
                      .width(500)
                      .height(300)
                      .url()}
                    alt="..."
                  />
                </div>

                <div className="flex justify-between px-6 mt-5 text-base text-slate-500 !dark:text-slate-100">
                  <div>
                    <span>
                      <Lead
                        className=" !mt-0 !text-start text-base font-normal"
                        lead={dayjs(d?.publishedAt).format("Do MMM, YYYY")}
                      />
                    </span>
                  </div>
                  <div>
                    <Lead
                      className=" !mt-0 !text-start text-base font-normal"
                      lead={"4 Mins Read"}
                    />
                  </div>
                </div>

                <div className="px-5 pb-10">
                  <Title
                    className="!text-2xl text-start cursor-pointer hover:text-blue-400 dark:hover:text-green-600 transition-colors duration-500"
                    title={d.title}
                    handleClick={() => handleReadMore(d)}
                  />

                  <Lead
                    className=" mt-0 !text-start mb-3 text-base font-normal "
                    lead={`${desc(d)} ....`}
                  />

                  <h4
                    className="inline-block cursor-pointer dark:text-slate-100 hover:text-blue-400 dark:hover:text-green-600"
                    onClick={() => {
                      const data = { article: d };
                      navigate(`/details/${d.slug.current}`, {
                        state: data,
                      });
                    }}
                  >
                    Read More...
                  </h4>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {location === "homepage" ? (
        <ViewAllBlogs />
      ) : (
        limit < blogPost?.length && (
          <div className="flex justify-center max-w-lg px-6 m-auto my-10 justify-items-center">
            <button
              className="block px-20 py-4 text-center text-blue-500 transition-all duration-500 ease-in-out border border-blue-500 rounded-full hover:bg-blue-400 hover:text-slate-100 dark:border-slate-200 dark:text-slate-100"
              onClick={showMoreItems}
            >
              Load More Blog Articles
            </button>
          </div>
        )
      )}
    </div>
  );
};

export default Blog;
